<template>
  <v-navigation-drawer
    id="default-drawer"
    v-model="drawer"
    :dark="dark"
    :right="rtl"
    :src="drawerImage ? image : ''"
    :mini-variant.sync="mini"
    mini-variant-width="80"
    app
    width="260"
  >
    <template
      v-if="drawerImage"
    >
      <v-img
        :key="image"
        :gradient="gradients[1]"
      />
    </template>

    <div class="px-2">
      <default-drawer-header />

      <v-divider class="mx-3 mb-2" />

      <v-treeview v-if="getLoggedIn" :items="menu" open-all>
        <template slot="label" slot-scope="props">
          <router-link :to="props.item.to" v-if="props.item.to">{{ props.item.name }}</router-link>
          <span v-else>{{ props.item.name }}</span>
      </template>
      </v-treeview>
    </div>

    <template #append>
      <div class="pa-4 text-center">
        
      </div>
    </template>

    <div class="pt-12" />
  </v-navigation-drawer>
</template>

<script>

  export default {
    name: 'DefaultDrawer',

    components: {
      DefaultDrawerHeader: () => import(
        /* webpackChunkName: "default-drawer-header" */
        './widgets/DrawerHeader'
      ),
    },
    data() {
      return {
        dark: true,
        gradients: [
            'rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)',
            'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)',
            'rgba(244, 67, 54, .8), rgba(244, 67, 54, .8)',
          ],
        image: null,
        mini: false,     
        menu: []
      }
    },
    methods: {
      
      getApotheken() {
        let result = [          
        {
            id: 2,
            name: "Übersicht",
            to: "/dashboard/" + this.$store.getters.getApotheke.id,
        },
        {
            id: 3,
            name: "Lager",
            children: [
              { 
                id: 4, 
                name: "Lagerentwicklung",
                to: "/lagerentwicklung/" + this.$store.getters.getApotheke.id,
              },
              { 
                id: 5, 
                name: "Lagerverteilung",
                to: "/lagerverteilung/" + this.$store.getters.getApotheke.id,
              },
              { 
                id: 12, 
                name: "Lagerumschlag",
                to: "/lagerumschlag/" + this.$store.getters.getApotheke.id,
              },
            ],
        },
        {
            id: 6,
            name: "Verkauf",
            children: [              
              { 
                id: 13, 
                name: "Entw. Packungen",
                to: "/abgegebenePackungen/" + this.$store.getters.getApotheke.id,
              },
              { 
                id: 7, 
                name: "Entw. Lieferfähigkeit",
                to: "/lieferfaehigkeitEntwicklung/" + this.$store.getters.getApotheke.id,
              },
              { 
                id: 8, 
                name: "Verteilung Besorger",
                to: "/verteilungBesorger/" + this.$store.getters.getApotheke.id,                
              },
              { 
                id: 11, 
                name: "Verteilung Verkäufe",
                to: "/verkaufVerteilung/" + this.$store.getters.getApotheke.id,                
              }
            ],
        },
        {
            id: 9,
            name: "Einkauf",
            children: [
              { 
                id: 10, 
                name: "Entw. Wareneingang",
                to: "/wareneingangEntwicklung/" + this.$store.getters.getApotheke.id,
              },
            ],
        },
        {
            id: 16,
            name: "Kontrolllisten",
            children: [
              { 
                id: 17, 
                name: "Eiserne Bestände",
                to: "/eiserneBestaende/" + this.$store.getters.getApotheke.id,
              },
              { 
                id: 19, 
                name: "Download",
                to: "/downloadlist/" + this.$store.getters.getApotheke.id,
              },
            ],
        },
        {
            id: 14,
            name: "Einstellungen",
            children: [
              { 
                id: 15, 
                name: "Lagerorte",
                to: "/lagerorte/" + this.$store.getters.getApotheke.id,
              },
              { 
                id: 18, 
                name: "Listungsgrenzen",
                to: "/listungsgrenzen/" + this.$store.getters.getApotheke.id,
              },
              { 
                id: 20, 
                name: "Mengensteuerung",
                to: "/mengensteuerung/" + this.$store.getters.getApotheke.id,
              },
            ],
        }
      ];

        if (this.$store.getters.getRole != "user") {
          result.unshift(
            { id: 1, name: 'Apothekenauswahl', to: '/Pharmacies' }
          );
        }
        if (this.$store.getters.getRole == "admin") {
          result.push(
            { id: 100, name: 'Benutzerverwaltung', to: '/useradministration' }
          );
        }
        this.menu = result;
      },
    },
    computed: {
      //mini() {
      //  return this.$store.state.mini;
      //},
      drawer: {
        get () {
          return this.$store.state.drawer;
        },
        set (value) {
          this.$store.commit('changeDrawer', value);
        },
      },
      drawerImage() {
        return this.$store.state.drawerImage;
      },
      rtl() {
        return this.$store.state.rtl;
      },
      getLoggedIn() {
        return this.$store.state.loggedIn;
      }
    },
    created() {
      this.$eventHub.$on('apothekeSelected', this.getApotheken);
    },
    beforeDestroy() {
      this.$eventHub.$off('apothekeSelected');
    },
  }
</script>

<style scoped>
a:link {
  color: #fff;
  text-decoration: none;
}
a:visited {
  color: #fff;
  text-decoration: none;
}
a:hover {
  color: #fff;
  text-decoration: none;
}
a:active {
  color: #fff;
  text-decoration: none;
}
.router-link-exact-visited {    
  color: #fff;
  text-decoration: none;
}
.router-link-exact-active {
  color: #fff;
  text-decoration: underline;
}
</style>
